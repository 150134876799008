.root {
  color: var(--Dark-Green, #132A30);
  background-color: white;
  font-family: var(--font-family);
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 22px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 100px var(--spacing-spacing-small) var(--spacing-spacing-small);
  .title {
    p {
      margin: 0;
    }
    strong {
      color: var(--color-dominant-secondary);
    }
  }
  .tag {
    font-family: var(--font-family-heading);
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%; /* 15px */
    letter-spacing: 0.3px;
    text-transform: uppercase;

    background-color: var(--color-dominant-secondary);
    border-radius: 5px;
    padding: 4px 8px;
    display: inline-block;
  }
  .media {
    position: relative;
  }
  .brand {
    background-color: var(--color-primary-300);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    &.brand1 {
      top: -60px;
      left: 88px;
      width: 176px;
      height: 176px;
    }
    &.brand2 {
      top: 210px;
      right: 16px;
      width: 152px;
      height: 152px;
    }
    &.brand3 {
      bottom: -34px;
      left: 122px;
      width: 152px;
      height: 152px;
    }
  }
}

.grey {
  color: var(--Dark-Green);
  opacity: 0.8;
}

.why {
  max-width: 760px;
  margin: 80px auto 60px auto;
}

.brands {
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  .track {
    display: flex;
    align-items: center;
    gap: 90px;
    white-space: nowrap;
    will-change: transform;
    animation: scroll 20s linear infinite;
  }
  .brandItem {
    flex-shrink: 0;
  }
}

@keyframes scroll {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

.howTo {
  background: #F3FCFF;
  padding: 80px 0;
  .top {
    max-width: 760px;
    margin: 0 auto;
    .description {
      color: rgba(#132A30, 0.5);
      padding: 0 22px;
      p {
        margin: 0;
      }
      strong {
        color: var(--Dark-Green);
      }
    }
  }
  .content {
    max-width: 992px;
    margin: 46px auto;
    display: flex;
    flex-direction: column;
    gap: 60px;

    .step {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-flow: dense;
      gap: 32px;

      .picture {
        background-color: var(--color-secondary-100);
        background-image: url('../../assets/images/blue-star.svg');
        background-repeat: no-repeat;
        background-position: 30px 30px;
        border-radius: 12px;
        width: 100%;
        height: 330px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:nth-child(even) > div:first-child {
        order: 1;
        &.picture {
          background-position: 160px 30px;
        }
      }
    }

    .stepNumber {
      color: var(--color-dominant-secondary);
      text-transform: uppercase;
      font-size: 13px;
    }
  }
}

.safe {
  max-width: 1254px;
  margin: 0 auto;
  padding: 16px;
  background-color: var(--color-secondary-100);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 32px;
}

@media (max-width: 767px) {
  .header {
    padding: 20px;
    .title {
      font-size: 34px;
    }
    .media {
      display: none;
    }
  }
  .why {
    .title {
      font-size: 32px;
    }
  }
  .howTo {
    padding: 20px;
    .content {
      .step {
        display: flex ;
        flex-direction: column;
        > div {
          order: -1 !important;
        }
      }
    }
  }
  .safe {
    flex-direction: column;
  }
}