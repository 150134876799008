@mixin label-default {
    /* label/default */
    font-family: var(--font-family);
    font-size: var(--font-size-font-size-3);
    font-style: normal;
    font-weight: 500;
    line-height: var(--line-height-text);
}

@mixin label-large {
    /* label/large */
    @include label-default;
    font-size: var(--font-size-font-size-4);
}

@mixin title-modal-title {
    /* title/modal-title */
    font-family: var(--font-family);
    font-size: var(--font-size-font-size-6);
    font-style: normal;
    font-weight: 617;
    line-height: var(--line-height-heading);
    letter-spacing: 0.48px;
}


@mixin text-body-small {
    /* text/body small */
    font-family: var(--font-family);
    font-size: var(--font-size-font-size-1);
    font-style: normal;
    font-weight: 400;
    line-height: var(--line-height-text);
}

@mixin text-body-small-bold {
    /* text/body small bold */
    @include text-body-small;
    font-family: var(--font-family-heading);
}

@mixin text-body-medium {
    /* text/body medium */
    @include text-body-small;
    font-size: var(--font-size-font-size-5);
}

@mixin text-body-medium-bold {
    /* text/body medium bold */
    @include text-body-medium;
    font-family: var(--font-family-heading);
}

@mixin section-title-small {
    /* section/title small */
    color: var(--Dark-Green, #132A30);
    font-family: var(--font-family);
    font-size: 25px;
    font-style: normal;
    font-weight: 617;
    line-height: 120%; /* 30px */
}
@mixin section-title-medium {
    /* section/title medium */
    @include section-title-small;
    font-size: 42px;
    font-weight: 469;
    line-height: 118.4%; /* 49.728px */
    letter-spacing: 0.84px;
}
@mixin section-title-large {
    /* section/title large */
    @include section-title-small;
    font-family: var(--font-family-heading);
    font-size: 51px;
    line-height: 100%;
    letter-spacing: 1.02px;
}