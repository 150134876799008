@mixin button {
  font-family: var(--font-family);
  font-size: 20px;
  background: var(--color-green-300);
  text-transform: none;
  border: 1px solid var(--Dark-Green);
  border-radius: 8px;
  box-shadow: 8px 8px 0 0 var(--Dark-Green);
  padding: 8px 16px;
  width: max-content;

  &:hover {
    background: var(--color-green-100);
  }

  .endIcon {
    background: var(--Dark-Green);
    border-radius: 50%;
    padding: 4px;
    margin-left: 10px;
    svg {
      fill: var(--color-green-300);
    }
  }
  &.disabled {
    .endIcon {
      background-color: rgba(0, 0, 0, 0.26);
    }
  }
}


.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;

  .triggerButtons {
    display: flex;
    gap: 42px;
  }

  .button {
    @include button;
  }
  .buttonPdf {
    @include button;
    background: #FFFFFF;
    box-shadow: 8px 8px 0 0 var(--color-green-300);
    &:hover {
      background: #F0F0F0;
    }
    .endIcon {
      background: var(--color-green-300);
      border: 1px solid var(--Dark-Green);
      svg {
        fill: var(--Dark-Green);
      }
    }
  }
}

.dialog {
  .paper {
    max-width: 560px;
  }
  .button {
    @include button;
    font-size: 14px;
    padding: 8px;
    width: 100%;
  }

  .dialogTitle {
    padding: 8px 8px 0;
    display: flex;
    justify-content: flex-end;
  }

  .headIcon {
    background-color: var(--color-primary-300, #86FFBD);
    width: 102px;
    height: 102px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 46px;
    }

    &.error {
      background-color: var(--color-KOL-status-not-ok);
      svg {
        fill: var(--color-alert-error);
      }
    }
  }

  .dialogContent {
    .body {
      color: #132A30CC;
      font-size: 18px;
      white-space: normal;
      ol {
        padding-left: 16px;
        line-height: normal;
        li {
          margin-bottom: 8px;
        }
      }
      p {
        margin-bottom: 0;
      }
      strong {
        color: var(--Dark-Green);
        font-family: var(--font-family-var);
      }
    }
  }

  .dialogActions {
    padding: 24px;
  }
}

.textField {
  input {
    padding: 16px;
  }
  &.focused .notchedOutline {
    border-color: #ccd6e3 !important;
    outline-color: #ccd6e3 !important;
    outline-offset: 0;
  }
}

.radio {
  color: var(--color-primary-300) !important;
  &.checked {
    color: var(--color-primary-300) !important;
  }
}

.termsConditions {
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }
  a:hover {
    text-decoration: underline;
  }
}

@media (max-width: 767px) {
  .dialog {
    .paper {
      max-width: 100%;
      max-height: calc(100% - 32px);
    }
  }
  .wrapper {
    .triggerButtons {
      flex-direction: row;
      flex-wrap: wrap;
      button {
        flex-basis: 100%;
      }
    }
  }
}