@use 'css/scss/typography';

.text {
    color: var(--Dark-Green);
    margin: initial;
    max-width: 100%;
    white-space: pre-wrap;

    &.labelDefault {
        @include typography.label-default;
    }

    &.labelLarge {
        @include typography.label-large;
    }

    &.textBodySmall {
        @include typography.text-body-small;
    }

    &.textBodySmallBold {
        @include typography.text-body-small-bold;
    }

    &.textBodyMedium {
        @include typography.text-body-medium;
    }

    &.textBodyMediumBold {
        @include typography.text-body-medium-bold;
    }

    &.titleModalTitle {
        @include typography.title-modal-title;
    }

    &.sectionTitleSmall {
        @include typography.section-title-small;
    }

    &.sectionTitleMedium {
        @include typography.section-title-medium;
    }

    &.sectionTitleLarge {
        @include typography.section-title-large;
    }
}